<template>
  <v-container fluid>
    <div>
      <v-toolbar rounded class="mb-6">
        <v-toolbar-title class="text-h5" elevation="20">Bases</v-toolbar-title>
        <v-badge
          class="ml-4"
          color="primary"
          :content="basesNumber"
          overlap
        ></v-badge>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          @click="openCreateModal"
          title="Demande de création de bases"
          >Créer</v-btn
        >
      </v-toolbar>

      <v-progress-circular
        v-if="loader"
        class="loader"
        :size="70"
        :width="10"
        color="purple"
        indeterminate
      >
      </v-progress-circular>

      <v-card-title class="pa-0 pb-2">
        <div class="d-flex align-center" style="width: 100%; gap: 1rem">
          <!-- afficher 10 item par defaut -->
          <v-text-field
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Rechercher"
            single-line
            hide-details
            clearable
          >
          </v-text-field>
          <!-- icon qui ouvre un menu de filtres sur les demande en cours et terminées -->
          <v-btn-toggle rounded dense>
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">
                  <v-icon color="primary" class="mr-2" dark small
                    >mdi-filter</v-icon
                  >
                  <v-chip
                    color="primary"
                    small
                    style="font-size: 0.7rem; color: #fff"
                    >Filtres</v-chip
                  >
                </v-btn>
              </template>
              <v-list>
                <v-list-item class="d-flex pa-0" style="gap: 0.5rem">
                  <v-chip
                    class="ml-2"
                    :style="getChipStyle('En_cours')"
                    @click="byProgress('En_cours')"
                  >
                    En cours
                  </v-chip>
                  <v-chip
                    class="ml-2"
                    :style="getChipStyle('Terminée')"
                    @click="byProgress('Terminée')"
                  >
                    Terminées
                  </v-chip>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-btn-toggle>
        </div>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="basesToShow"
        :search="search"
        :loading="loader"
        :items-per-page="10"
        class="elevation-2"
        :footer-props="{
          'items-per-page-text': $t('doubleAuth.rows-per-page'),
          'items-per-page-all-text': $t('doubleAuth.all'),
        }"
      >
        <template v-slot:item.logo="{ item }">
          <v-avatar size="50">
            <img :src="generateLogoUrl(item.logo)" />
          </v-avatar>
        </template>
        <!-- icon check si true sinon icon pas check -->
        <template v-slot:item.requester="{ item }">
          {{ item.requester ? item.requester : "Inconnu" }}
        </template>

        <!-- rendre clicquable le lien avec @click.stop -->
        <template v-slot:item.requestedURLs="{ item }">
          <a @click.stop="baseRedirect(item._id)" class="">{{
            item.requestedURLs
          }}</a>
        </template>

        <!-- formater la date -->
        <template v-slot:item.validateBaseDate="{ item }">
          <!-- iconde calendrier check -->
          <span v-if="item.validateBaseDate">
            <v-icon class="mr-2" color="green">mdi-calendar-check</v-icon>
            {{ item.validateBaseDate | formatDate }}
          </span>
          <span v-else>
            <v-icon class="mr-2" color="red">mdi-calendar-remove</v-icon>
            En attente
          </span>
        </template>

        <!-- mettre un switch a actif -->
        <template v-slot:item.isActive="{ item }">
          <div style="max-width: 100px">
            <v-switch
              v-if="!item.isActive"
              dense
              v-model="item.isActive"
              @change="onSwitchChange(item._id)"
              :disabled="!item.step3"
              :error-messages="!item.step3 ? 'Base non validée' : ''"
            ></v-switch>
            <v-switch
              v-else
              dense
              v-model="item.isActive"
              @change="onSwitchChange(item._id)"
              :color="item.isActive ? 'success' : ''"
            ></v-switch>
          </div>
        </template>

        <!-- action -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex align-center justify-end">
            <v-icon
              color="primary"
              class="mr-2"
              title="Informations de la base"
              @click="openDetailsModal(item._id)"
            >
              mdi-information-outline
            </v-icon>

            <!-- icon de demande d'archivage mdi-receipt-text-send -->
            <v-icon
              v-if="item.step5 && !item.isSendArchive"
              title="Envoyer la demande d\'archivage"
              class="mr-2 scale-hover"
              @click="openSendArchiveModal(item._id)"
            >
              mdi-send-lock
            </v-icon>

            <v-icon
              :title="
                item.step5 ? 'Demande terminée' : 'Progression de la demande'
              "
              :color="item.step5 ? 'success' : item.step2 ? 'warning' : 'grey'"
              class="mr-2 scale-hover"
              @click="!item.step5 && openProgressModal(item._id)"
              :style="{ cursor: item.step5 ? 'auto' : 'pointer' }"
            >
              {{ item.step5 ? "mdi-progress-check" : "mdi-progress-wrench" }}
            </v-icon>
            <v-icon
              title="Editer la base"
              color="warning"
              class="mr-2"
              @click="openUpdateModal(item._id)"
            >
              mdi-square-edit-outline
            </v-icon>
            <!-- <v-icon
              class="mr-2"
              v-if="
                ((rights && rights.direction) || rights.sysAdmin) && item.step5
              "
              :title="item.archive ? 'Désarchiver la base' : 'Archiver la base'"
              @click="openArchiveModal(item._id)"
              :color="item.archive ? 'warning' : 'red'"
            >
              {{
                item.archive ? "mdi-archive-arrow-up" : "mdi-archive-arrow-down"
              }}
            </v-icon> -->
            <!-- Conteneur pour l'icône et le badge -->
            <div class="icon-container">
              <v-badge 
                v-if="((rights && rights.direction) || rights.sysAdmin) && 
                      item.step5 && 
                      item.isSendArchive && 
                      !item.archive"
                content="1"
                :value="item.archiveRequest"
                color="primary"
                offset-x="20"
                offset-y="10"
                bordered
                overlap
              >
                <v-icon
                  class="mr-2"
                  v-if="((rights && rights.direction) || rights.sysAdmin) && item.step5"
                  :title="item.archive ? 'Désarchiver la base' : 'Archiver la base'"
                  @click="openArchiveModal(item._id)"
                  :color="item.archive ? 'warning' : 'red'"
                >
                  {{ item.archive ? "mdi-archive-arrow-up" : "mdi-archive-arrow-down" }}
                </v-icon>
              </v-badge>

              <!-- Icône sans badge si la base est archivée -->
              <v-icon
                v-else-if="((rights && rights.direction) || rights.sysAdmin) && item.step5"
                class="mr-2"
                :title="item.archive ? 'Désarchiver la base' : 'Archiver la base'"
                @click="openArchiveModal(item._id)"
                :color="item.archive ? 'warning' : 'red'"
              >
                {{ item.archive ? "mdi-archive-arrow-up" : "mdi-archive-arrow-down" }}
              </v-icon>
            </div>
            <v-icon
              v-if="(rights && rights.direction) || rights.sysAdmin"
              color="red"
              title="Supprimer la base"
              class="mr-2"
              @click="openAlertDeleteBase(item._id)"
            >
              mdi-delete
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </div>

    <!-- modale d'archivage -->
    <v-dialog v-model="dialogArchive" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            <v-icon left>
              {{
                getSelectedBase && getSelectedBase.archive
                  ? "mdi-archive-off"
                  : "mdi-archive"
              }}
            </v-icon>
            {{
              getSelectedBase && getSelectedBase.archive
                ? "Désarchiver la base"
                : "Archiver la base"
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogArchive = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pt-4">
          <!-- Alerte -->
          <v-alert prominent text color="warning" class="mb-4">
            <v-row align="center" no-gutters>
              <v-col>
                <strong class="subtitle-1">Attention !</strong><br />
                {{
                  getSelectedBase && getSelectedBase.archive
                    ? "Vous êtes sur le point de désarchiver la base."
                    : "Vous êtes sur le point d'archiver la base."
                }}
              </v-col>
              <v-col cols="auto">
                <v-icon large>mdi-alert</v-icon>
              </v-col>
            </v-row>
          </v-alert>

          <!-- Historique -->
          <v-expand-transition>
            <div v-if="baseRequesterArchiv && baseRequesterArchivReason">
              <v-card outlined class="mb-4">
                <v-card-title class="subtitle-1 py-2 grey lighten-4">
                  <v-icon left small>mdi-history</v-icon>
                  Historique d'archivage
                </v-card-title>

                <v-list>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2"
                        >Requêteur</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        baseRequesterArchiv
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-calendar</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2"
                        >Date de la demande</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        archiveRequesterDateData
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-text-box</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2"
                        >Raison de la demande</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        baseRequesterArchivReason
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </div>
          </v-expand-transition>

          <!-- Champ de raison -->
          <v-card outlined>
            <v-card-text>
              <v-textarea
                v-if="getSelectedBase && getSelectedBase.archive"
                v-model="unarchiveReason"
                label="Raison de désarchivage"
                placeholder="Veuillez indiquer la raison du désarchivage..."
                outlined
                rows="3"
                :rules="[(v) => !!v || 'La raison est requise']"
                :error-messages="
                  !isValidArchiveReason ? 'Veuillez saisir une raison' : ''
                "
              ></v-textarea>
              <v-textarea
                v-else
                v-model="archiveReason"
                label="Raison de l'archivage"
                placeholder="Veuillez indiquer la raison de l'archivage..."
                outlined
                rows="3"
                :rules="[(v) => !!v || 'La raison est requise']"
                :error-messages="
                  !isValidArchiveReason ? 'Veuillez saisir une raison' : ''
                "
              ></v-textarea>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="dialogArchive = false">
            Annuler
          </v-btn>
          <v-btn
            :color="
              getSelectedBase && getSelectedBase.archive ? 'success' : 'error'
            "
            :disabled="!isValidArchiveReason"
            dark
            @click="saveArchive"
          >
            <v-icon left>
              {{
                getSelectedBase && getSelectedBase.archive
                  ? "mdi-archive-off"
                  : "mdi-archive"
              }}
            </v-icon>
            {{
              getSelectedBase && getSelectedBase.archive
                ? "Désarchiver"
                : "Archiver"
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- modale parametrage -->
    <v-dialog v-model="dialogParams" max-width="60%">
      <basesParametrage @close-modal="closeParamsModal" />
    </v-dialog>
    <!-- modale creation de bases -->
    <v-dialog v-model="dialogCreate" max-width="90%">
      <basesCreate
        @close-modal="closeCreateModal"
        @client-created="refreshBasesList"
      />
    </v-dialog>
    <!-- modale update de bases -->
    <v-dialog v-model="dialogUpdate" max-width="90%">
      <basesUpdate @close-modal="closeUpdateModal" />
    </v-dialog>
    <!-- modale de suppression de bases -->
    <v-dialog v-model="alertDelete" max-width="25%">
      <alertDeleteBase
        @close-modal="closeAlertDeleteBase"
        @base-deleted="refreshBasesList"
      />
    </v-dialog>
    <!-- modale de liste de bases en attente -->
    <v-dialog v-model="dialogListBases" max-width="70%">
      <basesListToTcheck
        @close-modal="closeListBasesModal"
        @refresh-bases-list="refreshBasesList"
      />
    </v-dialog>
    <!-- modale du progression -->
    <v-dialog v-model="dialogProgress" max-width="90%">
      <baseSteppers
        @open-progress="openProgressModal"
        @refresh-bases-list="refreshBasesList"
      />
    </v-dialog>
    <!-- modale sysAdminTcheck -->
    <v-dialog v-model="dialogSysAdminTcheck" max-width="90%">
      <sysAdminTcheck @close-modal="closeSysAdminTcheckModal" />
    </v-dialog>
    <!-- liste parametrage -->
    <v-dialog v-model="dialogListModal" max-width="60%">
      <listesParametrage @closeParamsModal="closeListModal" />
    </v-dialog>
    <!-- modale details de bases -->
    <v-dialog v-model="dialogDetails" max-width="50%">
      <basesDetails @close-modal="closeDetailsModal" />
    </v-dialog>
    <!-- modale d alert de validation de demande d'archivage -->
    <v-dialog v-model="archiveModaleAlert" max-width="40%">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="primary" dark class="mb-2">
            <v-toolbar-title class="text-h5" elevation="20"
              >Demande d'archivage</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="archiveModaleAlert = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-alert prominent type="error">
              <v-row align="center">
                <v-col cols="grow">
                  <strong>Attention !</strong>
                  Vous êtes sur le point d'envoyer une demande d'archivage.
                </v-col>
                <v-col class="shrink">
                  <v-card-actions>
                    <v-btn color="" @click="archiveModaleAlert = false"
                      >Annuler</v-btn
                    >
                    <v-btn color="red" @click="sendRequestedArchiv"
                      >Envoyer</v-btn
                    >
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-alert>
            <v-row class="pa-3">
              <v-textarea
                label="Raison de l'archivage"
                v-model="archiveRequest"
                outlined
                dense
                rows="3"
              ></v-textarea>
            </v-row>
          </v-card-text>
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BasesService from "@/Services/SupportVision/BasesService";
import ClientService from "@/Services/SupportVision/ClientService";
import { mapMutations, mapState } from "vuex";
import alertDeleteBase from "./alertDeleteBase.vue";
import basesCreate from "./basesCreate.vue";
import basesListToTcheck from "./basesListToTcheck.vue";
import basesParametrage from "./basesParametrage.vue";
import basesUpdate from "./basesUpdate.vue";
import baseSteppers from "./baseSteppers.vue";
import sysAdminTcheck from "./sysAdminTcheck.vue";
import UserService from "@/Services/UserService";
import listesParametrage from "./listesParametrage.vue";
import basesDetails from "./details.vue";
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import moment from "moment";

export default {
  name: "Bases",
  components: {
    basesParametrage,
    basesCreate,
    basesUpdate,
    alertDeleteBase,
    basesListToTcheck,
    baseSteppers,
    sysAdminTcheck,
    listesParametrage,
    basesDetails,
  },
  data: () => ({
    dialogParams: false,
    dialogCreate: false,
    dialogUpdate: false,
    dialogArchive: false,
    alertDelete: false,
    dialogListBases: false,
    dialogListModal: false,
    dialogDetails: false,
    loader: false,
    search: "",
    location: "",
    isClientExpanded: {},
    dialogProgress: false,
    archiveReason: "",
    unarchiveReason: "",
    dialogSysAdminTcheck: false,
    clientsList: [],
    headers: [
      // logo
      { text: "Logo", value: "logo", groupable: false },
      { text: "Client", value: "client", groupable: true },
      // { text: "Nom de la base", value: "baseName", groupable: false },
      { text: "URL de la base", value: "requestedURLs", groupable: false },
      { text: "Demandeur", value: "requester", groupable: false },
      { text: "HTTPS", value: "isHTTPS", groupable: false },
      { text: "Usage", value: "usage", groupable: false },
      { text: "Type", value: "databaseType", groupable: false },
      { text: "Ouverture base", value: "validateBaseDate", groupable: false },
      { text: "Actif", value: "isActive", groupable: false },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        groupable: false,
      },
    ],
    byProgressStatus: "",
    byProgressStatutResult: [],
    isFilterProgressAcitve: false,
    isFilterDoneAcitve: false,
    archiveModaleAlert: false,
    archiveRequest: "",
    isSendArchive: false,
    archiveRequesterName: "",
    archiveRequesterDate: "",
  }),
  computed: {
    ...mapState([
      "baseList",
      "basesByClientCount",
      "informations",
      "basesParams",
      "configParams",
      "baseToTcheckId",
      "rights",
      "selectedBaseId",
    ]),
    numberBasesToTcheck() {
      return this.baseList.data.length > 0
        ? this.baseList.data.filter((base) => !base.isValidateBase).length
        : 0;
    },
    numberBasesToTcheckSysAdmin() {
      return this.baseList.data.length > 0
        ? this.baseList.data.filter(
            (base) => base.isValidateBase && !base.isOpenedToOutside
          ).length
        : 0;
    },
    basesToShow() {
      return this.isFilterProgressAcitve || this.isFilterDoneAcitve
        ? this.byProgressStatutResult.data
        : this.baseList.data;
    },
    isValidArchiveReason() {
      return this.archiveReason || this.unarchiveReason;
    },
    basesNumber() {
      return this.baseList && this.baseList.data
        ? this.baseList.data.length
        : 0;
    },
    baseRequesterArchiv() {
      return this.baseList.data.find((base) => base._id === this.selectedBaseId)
        ? this.baseList.data.find((base) => base._id === this.selectedBaseId)
            .archiveRequesterName
        : "";
    },
    baseRequesterArchivReason() {
      return this.baseList.data.find((base) => base._id === this.selectedBaseId)
        ? this.baseList.data.find((base) => base._id === this.selectedBaseId)
            .archiveRequest
        : "";
    },
    isArchive() {
      return this.baseList.data.find((base) => base._id === this.selectedBaseId)
        ? this.baseList.data.find((base) => base._id === this.selectedBaseId)
            .archive
        : false;
    },
    archiveRequesterDateData() {
      return this.baseList.data.find((base) => base._id === this.selectedBaseId)
        ? this.baseList.data.find((base) => base._id === this.selectedBaseId)
            .archiveRequesterDate
        : "";
    },
    getSelectedBase() {
      if (!this.baseList || !this.baseList.data || !this.selectedBaseId)
        return null;
      return this.baseList.data.find(
        (base) => base._id === this.selectedBaseId
      );
    },
  },
  watch: {
    dialogListBases(val) {
      if (val) {
        this.$store.commit("setBaseToTcheckId", null);
      }
    },
    dialogSysAdminTcheck(val) {
      if (val) {
        this.$store.commit("setBaseToTcheckId", null);
      }
    },
  },
  filters: {
    formatDate(date) {
      if (!date) return "";
      return new Date(date).toLocaleDateString("fr-FR");
    },
  },
  methods: {
    ...mapMutations([
      "setSelectedClientId",
      "setClientList",
      "setSelectedBaseId",
      "setBasesParams",
      "setUsersGtp",
      "setBasesRights",
      "setRights",
    ]),
    openSendArchiveModal(baseId) {
      this.setSelectedBaseId(baseId);
      this.archiveModaleAlert = true;
    },
    async sendRequestedArchiv() {
      const base = this.baseList.data.find(
        (base) => base._id === this.selectedBaseId
      );
      base.archiveRequest = this.archiveRequest;
      base.isSendArchive = true;
      base.archiveRequesterName =
        this.informations.firstname + " " + this.informations.lastname;
      base.archiveRequesterDate = moment().format("DD/MM/YYYY - HH:mm");
      await BasesService.updateBase(this.selectedBaseId, base);
      this.archiveModaleAlert = false;
      this.refreshBasesList();
    },
    getChipStyle(status) {
      const isDark = this.$vuetify.theme.dark;
      const isActive =
        (status === "En_cours" && this.isFilterProgressAcitve) ||
        (status === "Terminée" && this.isFilterDoneAcitve);

      // Couleurs pour le mode clair
      const lightBorderColor = status === "En_cours" ? "#3f51b5" : "#4caf50";
      const lightActiveColor = status === "En_cours" ? "#3f51b5" : "#4caf50";

      // Couleurs adaptées pour le mode sombre
      const darkBorderColor = status === "En_cours" ? "#7986cb" : "#81c784";
      const darkActiveColor = status === "En_cours" ? "#7986cb" : "#81c784";

      let style = {
        border: "2px solid",
        borderColor: isDark ? darkBorderColor : lightBorderColor,
        backgroundColor: isActive
          ? isDark
            ? darkActiveColor
            : lightActiveColor
          : isDark
          ? "transparent"
          : "#fff",
        color: isActive ? "#fff" : isDark ? darkBorderColor : lightBorderColor,
      };

      return Object.entries(style)
        .map(
          ([key, value]) =>
            `${key.replace(/([A-Z])/g, "-$1").toLowerCase()}: ${value}`
        )
        .join("; ");
    },
    closeListModal() {
      this.dialogListModal = false;
    },
    openListModal() {
      this.dialogListModal = true;
    },
    openParamsModal() {
      this.dialogParams = true;
    },
    async closeParamsModal() {
      this.dialogParams = false;
      // this.refreshBasesList();
    },
    openCreateModal() {
      this.dialogCreate = true;
    },
    closeCreateModal() {
      this.dialogCreate = false;
    },
    openUpdateModal(baseId) {
      this.setSelectedClientId(baseId);
      this.setSelectedBaseId(baseId);
      this.dialogUpdate = true;
    },
    openArchiveModal(baseId) {
      this.setSelectedBaseId(baseId);
      this.dialogArchive = true;
    },
    closeUpdateModal() {
      this.dialogUpdate = false;
      // this.refreshBasesList();
    },
    openAlertDeleteBase(baseId) {
      this.setSelectedClientId(baseId);
      this.alertDelete = true;
    },
    closeAlertDeleteBase() {
      this.alertDelete = false;
      // this.refreshBasesList();
    },
    openListBasesModal() {
      this.dialogListBases = true;
      // this.refreshBasesList();
    },
    closeListBasesModal() {
      this.dialogListBases = false;
    },
    openProgressModal(baseId) {
      this.setSelectedBaseId(baseId);
      this.dialogProgress = true;
    },
    openSysAdminTcheckModal() {
      this.dialogSysAdminTcheck = true;
      // this.refreshBasesList();
    },
    closeSysAdminTcheckModal() {
      this.dialogSysAdminTcheck = false;
      // this.refreshBasesList();
    },
    closeDetailsModal() {
      this.dialogDetails = false;
    },
    async openDetailsModal(baseId) {
      this.setSelectedClientId(baseId);
      this.$store.commit("setBaseToTcheckId", baseId);
      this.dialogDetails = true;
    },
    baseRedirect(baseId) {
      this.setSelectedBaseId(baseId);
      // ajouter http:// ou https:// si ce n'est pas le cas
      const base = this.baseList.data.find((base) => base._id === baseId);
      const url = base.requestedURLs;
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        window.open(`https://${url}`, "_blank").focus();
      } else {
        window.open(url, "_blank").focus();
      }
    },
    async refreshBasesList() {
      const bases = await BasesService.getBasesList();
      const baseUrl = bases.config.baseURL + bases.config.url;
      this.location = baseUrl;
      this.$store.commit("setBaseList", bases);
    },
    async alimentBases() {
      // Récupérer les bases existantes depuis le service
      let basesExist = await PlaneteOnlineService.getBases3();
      console.log("basesExist", basesExist);
      // pour chaque client dans this.clientsList, trouver les bases correspondantes dans basesExist qui sont dans un tableau de bases
      // console.log("this.clientsList", this.clientsList);
      for (const base in basesExist) {
        console.log("base", base);
        if (Object.hasOwnProperty.call(basesExist, base)) {
          const element = basesExist[base];
          for (const client in this.clientsList) {
            if (Object.hasOwnProperty.call(this.clientsList, client)) {
              const clientElement = this.clientsList[client];
              if (element.name === clientElement.name) {
                for (const base in element.bases) {
                  if (Object.hasOwnProperty.call(element.bases, base)) {
                    // console.log("element.bases[base]", element.bases[base]);
                    // console.log("clientElement.name", clientElement.name);
                    // console.log("element.name", element.name);
                    console.log(
                      "element.bases[base].name",
                      element.bases[base].name
                    );
                    // pour chauque bases on creer un objet base avec cette structure en renseignant les champs a null sauf ceux qui sont renseignés le nom de la bases et le tableau de bases
                    let baseObj = {
                      client: clientElement.name,
                      // logo: {
                      //   filename: "1c214145e1e2916466edb6ea7918a8b6",
                      //   mimetype: "image/png",
                      //   name: "Capture d’écran 2024-01-16 104837 (1).png"
                      // },
                      logo: element.bases[base].logo,
                      baseName: element.name,
                      requestedURLs: element.bases[base].name, // permet d acceder a la base dans le tableau de bases de basesExist
                      requestDate: moment().format("L"),
                      requestType: null,
                      databaseNature: element.bases[base].isGlobale,
                      timezone: element.bases[base].fuseauHoraire,
                      email: null,
                      globalActivation: null,
                      usage: element.bases[base].usage,
                      databaseType: element.bases[base].is_interim,
                      tops: null,
                      gta: null,
                      urlSdp: null,
                      sdpUrl: null,
                      urlSdc: null,
                      sdcUrl: null,
                      sourceDatabase: null,
                      copyUser: null,
                      copyHr: null,
                      copySdp: null,
                      copySdc: null,
                      storageSpace: null,
                      signatures: null,
                      employees: null,
                      isHTTPS: true,
                      isActive: true,
                      isValidateBase: true,
                      validateBaseDate: moment().format("L"),
                      isSysAdminValidate: true,
                      isOpenedToOutside: true,
                      step1: true,
                      step2: true,
                      step3: true,
                      step4: true,
                      step5: true,
                    };
                    // console.log("baseObj", baseObj);
                    // on envoie la base a la base de donnée
                    await BasesService.createBase(baseObj);
                    // on rafraichit la liste des bases
                    this.refreshBasesList();
                  }
                }
              }
            }
          }
        }
      }
    },
    async saveArchive() {
      const base = this.baseList.data.find(
        (base) => base._id === this.selectedBaseId
      );
      if (base.archive) {
        base.isActive = true;
        base.archive = false;
        base.unarchiveReason = this.unarchiveReason;
      } else {
        // Si la base n'est pas archivé, on l'archive
        base.isActive = false;
        base.archive = true;
        base.archiveReason = this.archiveReason;
      }
      await BasesService.updateBase(this.selectedBaseId, base);
      this.dialogArchive = false;
      this.refreshBasesList();
      this.archiveReason = "";
      this.unarchiveReason = "";
    },
    async onSwitchChange(baseId) {
      const base = this.baseList.data.find((base) => base._id === baseId);
      await BasesService.updateBase(baseId, base);
    },
    generateLogoUrl(logo) {
      // generer le logo depuis son url
      return logo;
      // if (logo && logo.filename && logo.mimetype) {
      //   return `${this.location}/read-file?id=${logo.filename}&mime=${logo.mimetype}`;
      // }
      // return '';
    },
    tcheckRights() {
      if (this.basesParams && this.basesParams.length > 0) {
        const user = this.informations;
        const params = this.basesParams[0].params;

        if (params) {
          this.$store.commit("setRights", { params: params, user: user });
        }
      } else {
        this.$store.commit("setConfigParams", true);
      }
    },
    async byProgress(status) {
      if (status === "En_cours") {
        if (status === "En_cours" && this.isFilterDoneAcitve) {
          // on annule le filtre en cours
          this.isFilterDoneAcitve = !this.isFilterDoneAcitve;
        }
        this.loader = true;
        this.byProgressStatus = status;
        const res = await BasesService.getBasesByStatut(status);
        this.byProgressStatutResult = res;
        this.loader = false;
        this.isFilterProgressAcitve = !this.isFilterProgressAcitve;
      } else if (status === "Terminée") {
        if (status === "Terminée" && this.isFilterProgressAcitve) {
          // on annule le filtre en cours
          this.isFilterProgressAcitve = !this.isFilterProgressAcitve;
        }
        this.loader = true;
        this.byProgressStatus = status;
        const res = await BasesService.getBasesByStatut(status);
        this.byProgressStatutResult = res;
        this.loader = false;
        this.isFilterDoneAcitve = !this.isFilterDoneAcitve;
      }
    },
  },
  async mounted() {
    window.location && window.location.search
      ? (this.search = window.location.search.split("=")[1].trim())
      : "";
    const urlParams = new URLSearchParams(window.location.search);
    // Vérifier si le paramètre "action" est défini sur "update"
    if (urlParams.has("action") && urlParams.get("action") === "update") {
      // Ouvrir la modale d'update avec l'ID de la base à mettre à jour
      this.openUpdateModal(urlParams.get("id"));
    }
    this.loader = true;
    this.clientsList = await ClientService.getClientList();
    await this.refreshBasesList();
    this.loader = false;
    this.$store.commit("setClientList", this.clientsList);
    const params = await BasesService.getParams();
    // params est un ojet
    this.$store.commit("setBasesParams", params);
    await UserService.getUsers().then((response) => {
      const user = response;
      this.$store.commit("setUsersGtp", user);
    });
    this.tcheckRights();
    this.$store.commit("setBasesRights", this.rights);

    // this.alimentBases();
  },
};
</script>
<style scoped>
.pointer {
  background-color: red;
}

.client-selected {
  border: 2px solid #3f51b5;
  background-color: #c5cae9;
}

.base-card {
  border-bottom: 1px solid #ccc;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
}

.custom-disabled {
  opacity: 0.5;
}

.scale-hover:hover {
  /* transform: scale(1.2);
  transition: all 0.3s ease-in-out; */
}

.icon-container {
  /* display: inline-block;
  position: relative; */
}
 
</style>
